import React, {FC, Fragment} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addHandlers,
  addMemoBoundary,
} from 'ad-hok'
import {generatePath} from 'react-router'
import {addPropIdentityStabilization} from 'ad-hok-utils'
import isDateBefore from 'date-fns/isBefore'

import {addClasses, makeClasses, sharedStyles} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import Grid from 'components/Grid'
import {addFormContext, addFormFieldStatusesContext} from 'utils/form/context'
import ListItem from 'components/ListItem'
import ListItemText from 'components/ListItemText'
import FormFeedbackIcon from 'components/FormFeedbackIcon'
import List from 'components/List'
import Divider from 'components/Divider'
import Heading from 'components/Heading'
import Caption from 'components/Caption'
import {getLongDate} from 'utils/date'
import SubmitButton from 'components/SubmitButton'
import {
  getSections,
  SectionForLink as SectionForLinkBase,
} from 'components/EditPersonForm/LeftColumn'
import Link from 'components/Link'
import {
  editPersonPath,
  medicaidApplicationViewPath,
} from 'components/TopLevelRoutes'
import {getFormattedSsn} from 'utils/ssn'
import {getPhoneSummary} from 'utils/phone'
import {getFormattedHomeAddress} from 'utils/address'
import NecessaryItemsCount from 'components/NecessaryItemsCount'
import ApplicationTitle from 'components/ApplicationTitle'
import DisplayItem from 'components/DisplayItem'
import {orUnknown} from 'utils/string'
import FormSectionLinkManual from 'components/FormSectionLinkManual'
import {dataAndDocumentsSectionName} from 'components/EditApplicationForm/DataAndDocumentsSection'
import TimeAgo from 'components/TimeAgo'
import {isClosedOrVoid} from 'utils/applicationStatuses'
import Button from 'components/Button'
import DisplayDob from 'components/DisplayDob'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {Application_application} from 'graphql/deserializedTypes/Application'
import {addShowDocumentFiles} from 'utils/configContext'
import {getShouldShowEditableFilesSections} from 'utils/editableFiles'

const classes = makeClasses((theme) => ({
  container: {
    ...sharedStyles.formLeftColumnContainer,
    width: 310,
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  title: {
    lineHeight: '20px',
  },
  firstDisplayItem: {
    marginTop: theme.spacing(2),
  },
  profileLinkIcon: {
    marginLeft: theme.spacing(1),
  },
  profileLink: {
    marginTop: theme.spacing(1),
  },
  topRowContainer: {
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  applicationViewLink: {
    marginTop: theme.spacing(2),
    fontSize: 12,
  },
}))

const stripSectionPrefix = (sectionName: string) =>
  sectionName.replace(/^application\./, '')

type SectionForLink = SectionForLinkBase & {
  labelTranslationKey?: string
}

interface FormSectionLinkProps {
  section: SectionForLink
}

const FormSectionLink: FC<FormSectionLinkProps> = flowMax(
  addDisplayName('FormSectionLink'),
  addTranslationHelpers,
  addFormContext,
  addProps(({section: {labelTranslationKey, name}, formName, t}) => ({
    label: labelTranslationKey
      ? t(labelTranslationKey)
      : t(`${formName}.sectionLabels.${stripSectionPrefix(name)}`),
  })),
  addHandlers({
    onClick: ({scrollToFormSection, section: {name}}) => () => {
      scrollToFormSection(name)
    },
  }),
  ({section: {name, sectionStatus}, label, onClick}) => (
    <ListItem button onClick={onClick}>
      <ListItemText primary={label} />
      {!!sectionStatus && (
        <FormFeedbackIcon
          status={sectionStatus.status}
          nameForTestId={`sectionSummary-${name}`}
        />
      )}
    </ListItem>
  )
)

export const outcomesSectionForLink: SectionForLink = {
  name: 'outcomes',
  sectionStatus: undefined,
}

export const fileTemplatesSectionForLink: SectionForLink = {
  name: 'fileTemplates',
  sectionStatus: undefined,
  labelTranslationKey: 'applicationForm.fileTemplatesLink',
}

export const householdSectionForLink: SectionForLink = {
  name: 'household',
  sectionStatus: undefined,
}

export const documentRequestSectionForLink: SectionForLink = {
  name: 'documentRequest',
  sectionStatus: undefined,
}

const FormSectionLinks: FC = flowMax(
  addDisplayName('FormSectionLinks'),
  addFormContext,
  addFormFieldStatusesContext,
  addProps(
    ({formFieldStatuses, formSections}) => ({
      sections: getSections({formFieldStatuses, formSections}),
    }),
    ['formFieldStatuses', 'formSections']
  ),
  addPropIdentityStabilization('sections'),
  addTranslationHelpers,
  addMemoBoundary(['sections']),
  addApplicationFormContext,
  addProps(
    ({application}) => ({
      shouldShowFileTemplatesSectionLink: getShouldShowEditableFilesSections(
        application
      ),
    }),
    ['application']
  ),
  addShowDocumentFiles,
  addMemoBoundary(['sections', 'hasNecessaryDocuments', 'showDocumentFiles']),
  ({
    sections,
    hasNecessaryDocuments,
    shouldShowFileTemplatesSectionLink,
    showDocumentFiles,
  }) => (
    <List>
      {sections.map((section, index) => (
        <Fragment key={section.name}>
          <FormSectionLink section={section} />
          {index !== sections.length - 1 && <Divider variant="middle" />}
        </Fragment>
      ))}
      <Divider variant="middle" />
      <FormSectionLink section={householdSectionForLink} />
      {shouldShowFileTemplatesSectionLink && (
        <>
          <Divider variant="middle" />
          <FormSectionLink section={fileTemplatesSectionForLink} />
        </>
      )}
      <Divider variant="middle" />
      <FormSectionLink section={outcomesSectionForLink} />
      {showDocumentFiles && (
        <>
          <Divider variant="middle" />
          <FormSectionLinkManual
            name={dataAndDocumentsSectionName}
            renderLabel={(label) => <ListItemText primary={label} />}
            shouldFlagNecessary={hasNecessaryDocuments}
          />
        </>
      )}
    </List>
  )
)

interface Props {
  application: Application_application
  policyId?: string
  showNecessaryCount?: boolean
}

const ApplicationFormLeftColumn: FC<Props> = flowMax(
  addDisplayName('ApplicationFormLeftColumn'),
  addMemoBoundary(['application', 'policyId']),
  addProps(({policyId}) => ({
    showPolicyId: policyId !== undefined,
  })),
  addClasses(classes),
  addTranslationHelpers,
  ({
    application: {
      benefit,
      status,
      person: {hospitalPatientId},
    },
    application,
    policyId,
    showPolicyId,
    showNecessaryCount,
    classes,
    t,
  }) => (
    <Grid item className={classes.container}>
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.topRowContainer}
      >
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.titleContainer}
          >
            <Heading variant="h6" className={classes.title}>
              <ApplicationTitle
                application={application}
                shouldShowSlideType={true}
                shouldShowMcdType={true}
              />
            </Heading>
            <SubmitButton testId="submit-application-form" />
          </Grid>
          <Caption>
            {t('applicationForm.created', {
              time: getLongDate(application.createdAt),
            })}
          </Caption>
          <TimeAgo
            time={
              isDateBefore(application.updatedAt, application.detailsUpdatedAt)
                ? application.detailsUpdatedAt
                : application.updatedAt
            }
          />
          <DisplayItem
            className={classes.firstDisplayItem}
            i18nKey="applicationForm.mrnDisplay"
            translations={{
              mrn: orUnknown(t)(hospitalPatientId),
            }}
          />
          {showPolicyId && (
            <DisplayItem
              i18nKey="applicationForm.policyIdDisplay"
              translations={{
                policyId: orUnknown(t)(policyId),
              }}
            />
          )}
          <DisplayItem
            i18nKey="applicationForm.personIdDisplay"
            translations={{
              id: orUnknown(t)(application.person.id),
            }}
          />
          <DisplayItem
            i18nKey="applicationForm.ssnDisplay"
            translations={{
              ssn: getFormattedSsn({ssn: application.person.ssn, t}),
            }}
          />
          <DisplayDob dob={application.person.dob} />
          <DisplayItem
            i18nKey="applicationForm.phoneDisplay"
            translations={{
              phone: getPhoneSummary({
                phoneNumbers: application.person.phoneNumbers,
                t,
              }),
            }}
          />
          <DisplayItem
            i18nKey="applicationForm.emailDisplay"
            translations={{
              email: orUnknown(t)(application.person.email),
            }}
          />
          <DisplayItem
            i18nKey="applicationForm.homeAddressDisplay"
            translations={{
              address: getFormattedHomeAddress({
                ...application.person,
                t,
              }),
            }}
          />
          <DisplayItem
            i18nKey="applicationForm.preferredLanguageDisplay"
            translations={{
              language: orUnknown(t)(application.person.preferredLanguage),
            }}
          />
          <Link
            highlight
            target="_self"
            to={generatePath(editPersonPath, {id: application.person.id})}
            className={classes.profileLink}
          >
            <Grid container direction="row" alignItems="center">
              {t('applicationForm.editPersonLink')}
            </Grid>
          </Link>
          {benefit === 'medicaid' && !isClosedOrVoid({benefit, status}) && (
            <Button
              target="_self"
              to={generatePath(medicaidApplicationViewPath, {
                id: application.id,
              })}
              component={Link}
              color="primary"
              variant="contained"
              className={classes.applicationViewLink}
            >
              {t('applicationForm.medicaidApplicationViewLink')}
            </Button>
          )}
        </Grid>
      </Grid>
      {showNecessaryCount ? (
        <NecessaryItemsCount testId="applicationForm-necessaryItemsCount" />
      ) : (
        <Divider variant="middle" />
      )}
      <FormSectionLinks />
    </Grid>
  )
)

export default ApplicationFormLeftColumn
