import React from 'react'
import BaseAppBar from '@material-ui/core/AppBar'
import HomeIcon from '@material-ui/icons/Home'
import {FC} from 'react'
import {flowMax, addDisplayName, branch, renderNothing} from 'ad-hok'
import purple from '@material-ui/core/colors/purple'

import Toolbar from 'components/Toolbar'
import Container from 'components/Container'
import Grid from 'components/Grid'
import Heading from 'components/Heading'
import {addTranslationHelpers} from 'utils/i18n'
import AccountMenu from 'components/AccountMenu'
import Link from 'components/Link'
import {makeClasses, addClasses} from 'theme'
import {showEnvWarning} from 'config/environment'
import {addLocation} from 'utils/routing'
import {isApplicationViewPath} from 'components/TopLevelRoutes'
import {addIsPatientPortal} from 'utils/patientPortal'

const classes = makeClasses((theme) => ({
  container: {
    ...(showEnvWarning ? {backgroundColor: purple[500]} : {}),
  },
  homeIcon: {
    marginRight: theme.spacing(1),
  },
  envWarning: {
    marginLeft: theme.spacing(10),
  },
}))

const AppBar: FC = flowMax(
  addDisplayName('AppBar'),
  addLocation,
  branch(
    ({location: {pathname}}) => isApplicationViewPath(pathname),
    renderNothing()
  ),
  addIsPatientPortal,
  addTranslationHelpers,
  addClasses(classes),
  ({isPatientPortal, t, classes}) => (
    <BaseAppBar position="sticky" className={classes.container}>
      <Toolbar>
        <Container>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              {!isPatientPortal && (
                <Link to="/">
                  <Grid container direction="row" alignItems="center">
                    <HomeIcon className={classes.homeIcon} />
                    <Heading variant="h6">{t('general.appName')}</Heading>
                  </Grid>
                </Link>
              )}
            </Grid>
            {showEnvWarning && (
              <Grid item>
                <Heading
                  variant="h3"
                  component="div"
                  className={classes.envWarning}
                >
                  {showEnvWarning.toUpperCase()}
                </Heading>
              </Grid>
            )}
            <Grid item>
              <AccountMenu />
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </BaseAppBar>
  )
)

export default AppBar
