import {TestContext} from 'yup'
import {isString, get} from 'lodash/fp'
import {TFunction} from 'i18next'

import {
  makeFormSchema,
  makeFormCollection,
  makeFormObject,
  FormValues,
  FormCanonicalValues,
  ExtractFormSchemaFields,
} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeGenderField,
  makeSsnField,
  makeLanguageField,
  makeBooleanField,
  makeStateField,
  makeZipCodeField,
  makeEmailField,
  makeMultiSelectField,
  makeSelectField,
  makeNumberField,
  makeCurrencyField,
  makePhoneNumbersCollection,
  makeIncomeFrequencyField,
  makeIncomeWorkTypeField,
  makeMonthSelectField,
  makeYearSelectField,
  makeIncomeTypeField,
  makePhoneField,
  makeDeductionTypeField,
  makeAssetTypeField,
  SelectFieldOptionsCallback,
  ValidatorTest,
  SelectFieldOption,
  makeYearField,
  makeEsignSessionStatusField,
} from 'utils/form/fieldTypes'
import {getExtendedName} from 'utils/name'

export const CI_DOCUMENT_CANT_PROVIDE = "Can't provide document"
export const CI_DOCUMENT_TYPE_US_PASSPORT = 'US passport'
export const CI_STATUS_US_CITIZEN = 'US citizen'
export const CI_STATUS_LEGAL_RESIDENT = 'Legal resident'
export const CI_STATUS_OTHER = 'Other'
export const CI_STATUS_OPTIONS = [
  CI_STATUS_US_CITIZEN,
  CI_STATUS_LEGAL_RESIDENT,
  CI_STATUS_OTHER,
]
export const MARITAL_STATUS_MARRIED = 'Married'
export const MARITAL_STATUS_SEPARATED = 'Separated'
export const TAX_FILING_STATUS_FILER = 'Primary tax filer (not filing jointly)'
export const TAX_FILING_STATUS_FILING_JOINTLY = 'Filing jointly with spouse'
export const TAX_FILING_STATUS_DEPENDENT = 'Tax dependent'
export const TAX_FILING_STATUS_NOT_FILING = 'Not planning to file tax'
export const CHANGE_JOB_LAST_6_MONTH_NO = 'No'
export const WHO_SUPPORT_WITHOUT_INCOME_SELF = 'Self'
export const WHO_SUPPORT_WITHOUT_INCOME_OTHER = 'Other'
export const RELATIONSHIP_TYPE_SPOUSE = 'Spouse'
export const RELATIONSHIP_TYPE_CHILD = 'Child'
export const RELATIONSHIP_TYPE_STEPCHILD = 'Stepchild'
export const RELATIONSHIP_TYPE_SIBLING_STEPSIBLING = 'Sibling/step-sibling'
export const RELATIONSHIP_TYPE_PARENT = 'Parent/legal guardian'
export const RELATIONSHIP_TYPE_STEPPARENT = 'Stepparent'
export const NJ_RESIDENCY_DOC_SELF_ATTESTATION = 'Self attestation of residency'
export const NJ_RESIDENCY_DOC_OTHERS_ATTESTATION =
  "Others' attestation of residency"
export const NJ_RESIDENCY_DOC_CANT_PROVIDE = "Can't provide document"
export const NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION = 'Homeless attestation'
export const IDENTITY_DOCUMENT_STATE_ID = "State ID (eg driver's license)"
export const IDENTITY_DOCUMENT_GOVERNMENT_ID =
  'Federal/local government ID card'
export const IDENTITY_DOCUMENT_PASSPORT = 'Passport'
export const IDENTITY_DOCUMENT_MILITARY_ID = 'US military ID card'
export const IDENTITY_DOCUMENT_SCHOOL_ID = 'School ID card'
export const IDENTITY_DOCUMENT_UTILITY = 'Utility/cable/rent/ lease/mortgage'
export const IDENTITY_DOCUMENT_BIRTH_CERTIFICATE = 'Birth certificate'
export const IDENTITY_DOCUMENT_SOCIAL_SECURITY_CARD = 'Social Security card'
export const IDENTITY_DOCUMENT_COUNTY_ID = 'County ID with photo'
export const IDENTITY_DOCUMENT_PAYSTUB = 'Paystub'
export const IDENTITY_DOCUMENT_EMPLOYEE_ID_CARD = 'Employee ID card'
export const IDENTITY_DOCUMENT_BAPTISMAL_CERTIFICATE = 'Baptismal certificate'
export const IDENTITY_DOCUMENT_OTHER = 'None of above'
export const IDENTITY_DOCUMENT_CANT_PROVIDE = "Can't provide"

const financialItemDateRangeValidatorTest: ValidatorTest = [
  'form.endBeforeStart',
  function (this: TestContext /*value: string*/) {
    const {startYear, startMonth, endYear, endMonth} = this.parent
    if (!startYear || !startMonth || !endYear || !endMonth) return true
    const startDate = `${startYear}-${startMonth}`
    const endDate = `${endYear}-${endMonth}`
    return endDate >= startDate
  },
]

export const incomeSourceSchema = {
  id: makeTextField(),
  incomeType: makeIncomeTypeField(),
  employerName: makeTextField(),
  employerAddressStreet: makeTextField(),
  employerAddressCity: makeTextField(),
  employerAddressState: makeStateField(),
  employerAddressZip: makeZipCodeField(),
  employerPhone: makePhoneField(),
  employerInsurance: makeBooleanField(),
  startMonth: makeMonthSelectField({
    validatorTest: financialItemDateRangeValidatorTest,
  }),
  startYear: makeYearSelectField(),
  endMonth: makeMonthSelectField(),
  endYear: makeYearSelectField(),
  fullPartTime: makeIncomeWorkTypeField(),
  amount: makeCurrencyField(),
  payFrequency: makeIncomeFrequencyField(),
  usedForEsign: makeBooleanField(),
  esignSessionId: makeTextField(),
  esignSessionStatus: makeEsignSessionStatusField(),
  proofOfIncome: makeBooleanField(),
  noProofOfIncomeReason: makeTextField(),
}

export const deductionSchema = {
  id: makeTextField(),
  deductionType: makeDeductionTypeField(),
  startMonth: makeMonthSelectField({
    validatorTest: financialItemDateRangeValidatorTest,
  }),
  startYear: makeYearSelectField(),
  endMonth: makeMonthSelectField(),
  endYear: makeYearSelectField(),
  amount: makeCurrencyField(),
  deductionFrequency: makeIncomeFrequencyField(),
}

export const assetSchema = {
  id: makeTextField(),
  assetType: makeAssetTypeField(),
  amount: makeCurrencyField(),
}

let dependentOfTaxFilerIdOptionsGlobal: SelectFieldOption[] = []
const getValidDependentOfTaxFilerIdsGlobal = () =>
  dependentOfTaxFilerIdOptionsGlobal.map(({value}) => value)

const getPeopleWithRelationshipToClient = ({
  person,
  person: {relationships},
}: FormValues<any>) => [
  {
    ...person,
    relationshipToClient: 'Client',
  },
  ...relationships.map(({otherPerson, relationshipType}: any) => ({
    ...otherPerson,
    relationshipToClient: relationshipType,
  })),
]

export const getDependentOfTaxFilerIdOptions: SelectFieldOptionsCallback = ({
  values,
  t,
}) => {
  const options = getPeopleWithRelationshipToClient(values)
    .filter(
      ({id, taxFilingStatus}) =>
        id &&
        [TAX_FILING_STATUS_FILER, TAX_FILING_STATUS_FILING_JOINTLY].includes(
          taxFilingStatus
        )
    )
    .map((person) => ({
      label: `${getExtendedName({...person, t})} - ${
        person.relationshipToClient || t('general.unknown')
      }`,
      value: person.id,
    }))

  dependentOfTaxFilerIdOptionsGlobal = options

  return options
}

const invalidDependentOfTaxFilerIdTest: ValidatorTest = [
  'personForm.invalidDependentOfTaxFilerIdErrorMessage',
  function (value: string) {
    if (!value) return true

    const validIds = getValidDependentOfTaxFilerIdsGlobal()

    return validIds.includes(value)
  },
]

export const showSelectedOptionForNonexistentDependentOfTaxFilerId = ({
  values,
  name,
  t,
}: {
  values: FormValues<any>
  name: string
  t: TFunction
}): SelectFieldOption => {
  const value = (get(name, values) as unknown) as string
  const foundPerson = getPeopleWithRelationshipToClient(values).find(
    (person) => person.id && person.id === value
  )
  if (!foundPerson)
    return {
      label: t(
        'personForm.invalidDependentOfTaxFilerIdOptionLabelUnknownPersonId',
        {id: value}
      ),
      value,
    }
  return {
    label: t('personForm.invalidDependentOfTaxFilerIdOptionLabelKnownPerson', {
      name: getExtendedName({...foundPerson, t}),
      relationshipType:
        foundPerson.relationshipToClient || t('general.unknown'),
    }),
    value,
  }
}

const sharedPersonSchema = {
  firstName: makeTextField(),
  middleName: makeTextField(),
  lastName: makeTextField(),
  suffix: makeTextField(),
  preferredName: makeTextField(),
  dob: makeDateField(),
  gender: makeGenderField(),
  ssn: makeSsnField(),
  phoneNumbers: makePhoneNumbersCollection(),
  homeAddressStreet: makeTextField(),
  homeAddressCity: makeTextField(),
  homeAddressState: makeStateField(),
  homeAddressZip: makeZipCodeField(),
  homeAddressComment: makeTextField(),
  mailingAddressStreet: makeTextField(),
  mailingAddressCity: makeTextField(),
  mailingAddressState: makeStateField(),
  mailingAddressZip: makeZipCodeField(),
  mailingAddressComment: makeTextField(),
  email: makeEmailField(),
  emailComment: makeTextField(),
  preferredLanguage: makeLanguageField(),
  hospitalPatientId: makeTextField(),
  hasInsurance: makeBooleanField(),
  livesInNj: makeBooleanField(),
  ciStatus: makeSelectField({
    options: CI_STATUS_OPTIONS.map((value) => ({
      value,
    })),
  }),
  ciDocumentType: makeSelectField({
    options: [
      CI_DOCUMENT_TYPE_US_PASSPORT,
      'US birth certificate',
      'Certificate of naturalization',
      'I-94',
      'Green card',
      'Employment authorization',
      'US citizen ID card',
      'Military record with birth location',
      'Adoption papers',
      'Grant letter from Asylum Office',
      'US Citizenship and Immigration Service petition and supporting documentation',
      CI_DOCUMENT_CANT_PROVIDE,
    ].map((value) => ({
      value,
    })),
  }),
  fiveYearsInUs: makeBooleanField(),
  specialCategory: makeBooleanField(),
  pregnant: makeBooleanField(),
  maritalStatus: makeSelectField({
    options: [
      'Single',
      MARITAL_STATUS_MARRIED,
      MARITAL_STATUS_SEPARATED,
      'Divorced',
      'Widowed',
    ].map((value) => ({value})),
  }),
  spouseLiveWithMe: makeBooleanField(),
  expectedChild: makeNumberField(),
  taxFilingStatus: makeSelectField({
    options: [
      TAX_FILING_STATUS_FILER,
      TAX_FILING_STATUS_FILING_JOINTLY,
      TAX_FILING_STATUS_DEPENDENT,
      TAX_FILING_STATUS_NOT_FILING,
    ].map((value) => ({value})),
  }),
  claimedBySomeoneOtherThanParents: makeBooleanField(),
  livingWithParentsWhoDoNotFileJointly: makeBooleanField(),
  estTaxDependentCount: makeNumberField(),
  taxDependentInclSpouseLiveWithClient: makeBooleanField(),
  estParentLiveWithClient: makeNumberField(),
  estChildren19LiveWithClient: makeNumberField(),
  estMinor19SiblingLiveWithClient: makeNumberField(),
  estHouseholdIncome: makeCurrencyField(),
  incomeSources: makeFormCollection(incomeSourceSchema),
  deductions: makeFormCollection(deductionSchema),
  assets: makeFormCollection(assetSchema),
  currentInsuranceName: makeTextField(),
  currentInsurancePolicyNo: makeTextField(),
  insuranceStartdate: makeDateField(),
  njResidencyDoc: makeSelectField({
    options: [
      IDENTITY_DOCUMENT_STATE_ID,
      IDENTITY_DOCUMENT_UTILITY,
      {
        value: NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION,
        label: `(CC only): ${NJ_RESIDENCY_DOC_HOMELESS_ATTESTATION}`,
      },
      {
        value: NJ_RESIDENCY_DOC_SELF_ATTESTATION,
        label: `(MCD only): ${NJ_RESIDENCY_DOC_SELF_ATTESTATION}`,
      },
      NJ_RESIDENCY_DOC_OTHERS_ATTESTATION,
      {
        value: NJ_RESIDENCY_DOC_CANT_PROVIDE,
        label: `(MCD only): ${NJ_RESIDENCY_DOC_CANT_PROVIDE}`,
      },
    ].map((value) => (isString(value) ? {value} : value)),
  }),
  ciDocumentName: makeTextField(),
  dateOfEntry: makeDateField(),
  uscisNo: makeTextField(),
  prCardNo: makeTextField(),
  dueDate: makeDateField(),
  spousePregnant: makeBooleanField(),
  isStudent: makeBooleanField(),
  dependentOfTaxFilerId: makeSelectField({
    options: getDependentOfTaxFilerIdOptions,
    validatorTest: invalidDependentOfTaxFilerIdTest,
  }),
  changeJobInLast6Mon: makeSelectField({
    options: [
      CHANGE_JOB_LAST_6_MONTH_NO,
      'Changed jobs',
      'Stopped working',
      'Started working fewer hours',
    ].map((value) => ({
      value,
    })),
  }),
  income: makeBooleanField(),
  whoSupportWithoutIncome: makeSelectField({
    options: [
      WHO_SUPPORT_WITHOUT_INCOME_SELF,
      WHO_SUPPORT_WITHOUT_INCOME_OTHER,
    ].map((value) => ({
      value,
    })),
  }),
  othersSupportWithoutIncome: makeTextField(),
  selfSupportWithoutIncome: makeTextField(),
  insuranceLast3Months: makeBooleanField(),
  wantsCoverage: makeBooleanField(),
  alaskanNativeamerican: makeBooleanField(),
  fosterCareAfter18: makeBooleanField(),
  identityDocument: makeSelectField({
    options: [
      IDENTITY_DOCUMENT_STATE_ID,
      IDENTITY_DOCUMENT_GOVERNMENT_ID,
      IDENTITY_DOCUMENT_PASSPORT,
      IDENTITY_DOCUMENT_MILITARY_ID,
      {
        value: IDENTITY_DOCUMENT_BIRTH_CERTIFICATE,
        label: `(CC only): ${IDENTITY_DOCUMENT_BIRTH_CERTIFICATE}`,
      },
      {
        value: IDENTITY_DOCUMENT_SOCIAL_SECURITY_CARD,
        label: `(CC only): ${IDENTITY_DOCUMENT_SOCIAL_SECURITY_CARD}`,
      },
      {
        value: IDENTITY_DOCUMENT_COUNTY_ID,
        label: `(CC only): ${IDENTITY_DOCUMENT_COUNTY_ID}`,
      },
      {
        value: IDENTITY_DOCUMENT_EMPLOYEE_ID_CARD,
        label: `(CC only): ${IDENTITY_DOCUMENT_EMPLOYEE_ID_CARD}`,
      },
      IDENTITY_DOCUMENT_SCHOOL_ID,
      {
        value: IDENTITY_DOCUMENT_PAYSTUB,
        label: `(CC only): ${IDENTITY_DOCUMENT_PAYSTUB}`,
      },
      {
        value: IDENTITY_DOCUMENT_UTILITY,
        label: `(CC only): ${IDENTITY_DOCUMENT_UTILITY}`,
      },
      {
        value: IDENTITY_DOCUMENT_BAPTISMAL_CERTIFICATE,
        label: `(CC only): ${IDENTITY_DOCUMENT_BAPTISMAL_CERTIFICATE}`,
      },
      IDENTITY_DOCUMENT_OTHER,
      IDENTITY_DOCUMENT_CANT_PROVIDE,
    ].map((value) => (isString(value) ? {value} : value)),
  }),
  desiredMco: makeSelectField({
    options: [
      'Aetna',
      'Amerigroup',
      'Horizon',
      'United',
      'Wellcare',
      'No preference',
    ].map((value) => ({
      value,
    })),
  }),
  pcp: makeTextField(),
  childrenPcp: makeTextField(),
  children18LiveWithClient: makeNumberField(),
  minor18SiblingLiveWithClient: makeNumberField(),
  pendingUsCitizenship: makeBooleanField(),
  livedInNjSinceMonth: makeMonthSelectField(),
  livedInNjSinceYear: makeYearField(),
  hasDeduction: makeBooleanField(),
  hasAsset: makeBooleanField(),
  noIncomeSinceMonth: makeMonthSelectField(),
  noIncomeSinceYear: makeYearSelectField(),
  noAssetSinceMonth: makeMonthSelectField(),
  noAssetSinceYear: makeYearSelectField(),
  homelessSinceMonth: makeMonthSelectField(),
  homelessSinceYear: makeYearSelectField(),
  supporterRelationship: makeTextField(),
  supportSinceMonth: makeMonthSelectField(),
  supportSinceYear: makeYearSelectField(),
  supportType: makeMultiSelectField({
    options: ['Food', 'Shelter', 'Cash'].map((value) => ({
      label: value,
      value,
    })),
  }),
  supporterAddressStreet: makeTextField(),
  supporterAddressCity: makeTextField(),
  supporterAddressState: makeStateField(),
  supporterAddressZip: makeZipCodeField(),
  supporterPhone: makePhoneField(),
  othersAttestationWho: makeTextField(),
  othersAttestationRelationship: makeTextField(),
  othersAttestationPhone: makePhoneField(),
  addressSinceMonth: makeMonthSelectField(),
  addressSinceYear: makeYearSelectField(),
  race: makeSelectField({
    options: ['White', 'African American', 'Hispanic', 'Other'].map(
      (value) => ({
        value,
      })
    ),
  }),
  currentInsuranceType: makeSelectField({
    options: [
      'Commercial',
      'Medicaid',
      'NJFC MCO',
      'Medicare',
      'Travelers',
    ].map((value) => ({
      value,
    })),
  }),
  currentInsuranceIncludesDental: makeBooleanField(),
  noIdentityDocReason: makeTextField(),
  ownNonresidentProperty: makeBooleanField(),
  nonresidentPropertyMailingAddressStreet: makeTextField(),
  nonresidentPropertyMailingAddressCity: makeTextField(),
  nonresidentPropertyMailingAddressState: makeStateField(),
  nonresidentPropertyMailingAddressZip: makeZipCodeField(),
  separatedSinceMonth: makeMonthSelectField(),
  separatedSinceYear: makeYearSelectField(),
  dontLiveWithSpouse: makeBooleanField(),
  noJointAssetsWithSpouse: makeBooleanField(),
  noFinancialSupportFromSpouse: makeBooleanField(),
  notFiledJointlySince: makeTextField(),
  reasonNotFiling: makeTextField(),
  lastKnownAddressStreet: makeTextField(),
  lastKnownAddressCity: makeTextField(),
  lastKnownAddressState: makeStateField(),
  lastKnownAddressZip: makeZipCodeField(),
}

export const editPersonFormSchema = makeFormSchema({
  fields: {
    person: {
      id: makeTextField({
        isRequired: true,
      }),
      note: makeTextField(),
      ...sharedPersonSchema,
      relationships: makeFormCollection({
        id: makeTextField(),
        relationshipType: makeSelectField({
          options: [
            RELATIONSHIP_TYPE_SPOUSE,
            RELATIONSHIP_TYPE_CHILD,
            RELATIONSHIP_TYPE_PARENT,
            RELATIONSHIP_TYPE_SIBLING_STEPSIBLING,
            'Domestic partner',
            RELATIONSHIP_TYPE_STEPPARENT,
            RELATIONSHIP_TYPE_STEPCHILD,
            'Uncle/aunt',
            'Nephew/niece',
            'Grandparent',
            'Cousin',
            "Parent's domestic partner",
            'Sibling-in-law',
            'Child-in-law',
            'Parent-in-law',
            "Domestic partner's child",
            'Grandchild',
            'Other',
          ].map((value) => ({value})),
        }),
        liveTogether: makeBooleanField(),
        otherPerson: makeFormObject({
          id: makeTextField(),
          ...sharedPersonSchema,
        }),
      }),
    },
  },
})

export type CanonicalValues = FormCanonicalValues<
  ExtractFormSchemaFields<typeof editPersonFormSchema>
>
export type ClientType = Omit<
  CanonicalValues['person'],
  'openApplications' | 'openTriages'
>
export type RelationshipType = ClientType['relationships'][0]
export type PersonType = ClientType | RelationshipType['otherPerson']
export type EditPersonFormPersonType = PersonType
export type PersonWithRelationshipType = PersonType & {
  relationshipType: string | null
  relationshipIndex?: number
  personValues: PersonType
}
