import gql from 'graphql-tag'

import {
  personFragment,
  taskFragment,
  accountFragment,
  triageFragment,
  applicationFragment,
  personForApplicationFragment,
  personQueryFragment,
  applicationQueryFragment,
  relationshipWithFullPersonFragment,
  personForAccountFragment,
  benefitOutcomeFragment,
  eligibilityDeterminationFragment,
  userFragment,
  applicationForStatusUpdateFragment,
  personForAwaitingPhoneNumberEsignSessionIdFragment,
  webformFragment,
  receivedWebformFragment,
  configFragment,
  inboundsQueryFragment,
  fileTemplateFragment,
  editableFileFragment,
  inboundWebformFragment,
  countyFaxFragment,
  stateFieldFragment,
  documentFileFragment,
} from './fragments'

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserFields
      email
      name
      openApplicationsWithUnreviewedRemoteRequestEditableFileCount
      openApplicationsWithUnreadMessagesCount
      roles
    }
  }
  ${userFragment}
`

export const PERSON_MATCH_QUERY = gql`
  query PersonMatch(
    $ssn: String
    $hospitalPatientId: String
    $firstName: String
    $lastName: String
    $dob: ISO8601Date
  ) {
    personMatch(
      ssn: $ssn
      hospitalPatientId: $hospitalPatientId
      firstName: $firstName
      lastName: $lastName
      dob: $dob
    ) {
      ...PersonFields
    }
  }
  ${personFragment}
`

export const PERSON_QUERY = gql`
  query Person($id: ID!) {
    person(id: $id) {
      ...PersonQueryFields
    }
  }
  ${personQueryFragment}
`

export const ACCOUNT_AUDITS_QUERY = gql`
  query AccountAudits($auditId: ID) {
    accountAudits(auditId: $auditId) {
      id
      auditExternalId
      accountNumber
      personFirstName
      personLastName
      dateOfService
      account {
        id
        readyForAudit
        hospitalAccountId
        documentFiles {
          ...DocumentFileFields
        }
      }
      applicationCount
      application {
        ...ApplicationFields
        person {
          ...PersonForApplicationFields
        }
      }
      benefitOutcome {
        ...BenefitOutcomeFields
      }
      auditId
      editableFiles {
        ...EditableFileFields
      }
    }
  }
  ${applicationFragment}
  ${personForApplicationFragment}
  ${benefitOutcomeFragment}
  ${documentFileFragment}
  ${editableFileFragment}
`

export const PERSON_FOR_AWAITING_PHONE_NUMBER_ESIGN_SESSION_ID_QUERY = gql`
  query PersonForAwaitingPhoneNumberEsignSessionId($id: ID!) {
    person(id: $id) {
      ...PersonForAwaitingPhoneNumberEsignSessionIdFields
    }
  }
  ${personForAwaitingPhoneNumberEsignSessionIdFragment}
`

export const TASKS_QUERY = gql`
  query Tasks(
    $taskTypes: [String!]
    $statuses: [String!]
    $assignedToIds: [ID!]
    $dueDateRanges: [DateRangeInput!]
  ) {
    tasks(
      taskTypes: $taskTypes
      statuses: $statuses
      assignedToIds: $assignedToIds
      dueDateRanges: $dueDateRanges
    ) {
      ...TaskFields
    }
  }
  ${taskFragment}
`

export const TRIAGES_QUERY = gql`
  query Triages(
    $statuses: [String!]
    $departments: [String!]
    $facilities: [String!]
    $insuranceTypes: [String!]
    $njmmisCheck: [String]
    $dateOfService: [String!]
    $hasDischargeDate: [String!]
    $isScheduled: [String!]
    $hasSsn: [String!]
    $ciStatus: [String!]
    $unreviewedWebformIds: [ID!]
    $assignedToIds: [ID!]
  ) {
    triages(
      statuses: $statuses
      departments: $departments
      facilities: $facilities
      insuranceTypes: $insuranceTypes
      njmmisCheck: $njmmisCheck
      dateOfService: $dateOfService
      hasDischargeDate: $hasDischargeDate
      isScheduled: $isScheduled
      hasSsn: $hasSsn
      ciStatus: $ciStatus
      unreviewedWebformIds: $unreviewedWebformIds
      assignedToIds: $assignedToIds
    ) {
      ...TriageFields
      account {
        person {
          ...PersonFields
        }
      }
      eligibilityDeterminations {
        id
      }
      assignedTo {
        ...UserFields
      }
    }
  }
  ${triageFragment}
  ${personFragment}
  ${userFragment}
`

export const ACCOUNT_QUERY = gql`
  query Account($id: ID, $hospitalAccountId: String) {
    account(id: $id, hospitalAccountId: $hospitalAccountId) {
      ...AccountFields
      person {
        ...PersonFields
      }
    }
  }
  ${accountFragment}
  ${personFragment}
`

export const FEDERAL_POVERTY_LEVELS_QUERY = gql`
  query FederalPovertyLevels(
    $benefit: String!
    $initialDateOfService: ISO8601Date
  ) {
    federalPovertyLevels(
      benefit: $benefit
      initialDateOfService: $initialDateOfService
    ) {
      id
      familySize
      monthlyIncome
    }
  }
`

export const APPLICATIONS_QUERY = gql`
  query Applications(
    $statuses: [String!]!
    $benefits: [String!]!
    $search: String
    $facilities: [String!]
    $locations: [String!]
    $initialDateOfService: [String!]
    $hasPolicyId: [String!]
    $submitDate: [String!]
    $esignNeedsReview: [String!]
    $completedEsignSessions: [String!]
    $mcdType: [String!]
    $age: [String!]
    $createdByIds: [ID!]
    $assignedToIds: [ID!]
    $unreviewedEditableFiles: [String!]
    $charityCareTypes: [String!]
    $hasUnreadTextMessages: [String!]
    $pastDateOfService: [String!]
    $slideType: [String!]
    $mcdSubtype: String
  ) {
    applications(
      statuses: $statuses
      benefits: $benefits
      search: $search
      facilities: $facilities
      locations: $locations
      initialDateOfService: $initialDateOfService
      hasPolicyId: $hasPolicyId
      submitDate: $submitDate
      esignNeedsReview: $esignNeedsReview
      completedEsignSessions: $completedEsignSessions
      mcdType: $mcdType
      age: $age
      createdByIds: $createdByIds
      assignedToIds: $assignedToIds
      unreviewedEditableFiles: $unreviewedEditableFiles
      charityCareTypes: $charityCareTypes
      hasUnreadTextMessages: $hasUnreadTextMessages
      pastDateOfService: $pastDateOfService
      slideType: $slideType
      mcdSubtype: $mcdSubtype
    ) {
      ...ApplicationFields
      person {
        ...PersonForApplicationFields
      }
      assignedTo {
        ...UserFields
      }
      ...ApplicationForStatusUpdateFields
    }
  }
  ${applicationFragment}
  ${personForApplicationFragment}
  ${applicationForStatusUpdateFragment}
  ${userFragment}
`

export const APPLICATION_QUERY = gql`
  query Application($id: ID!) {
    application(id: $id) {
      ...ApplicationQueryFields
    }
  }
  ${applicationQueryFragment}
`

export const APPLICATION_FOR_VIEW_QUERY = gql`
  query ApplicationForView($id: ID!) {
    application(id: $id) {
      ...ApplicationQueryFields
      person {
        ...PersonFields
        relationships {
          ...RelationshipWithFullPersonFields
        }
        accounts {
          id
          hospitalAccountId
          dateOfService
        }
      }
      householdMembers {
        person {
          ...PersonFields
        }
      }
    }
  }
  ${applicationQueryFragment}
  ${personFragment}
  ${relationshipWithFullPersonFragment}
`

export const ACCOUNTS_QUERY = gql`
  query Accounts(
    $statuses: [String!]
    $insuranceTypes: [String!]
    $dateOfServiceAfter: ISO8601Date
    $dateOfServiceBefore: ISO8601Date
    $facility: [String!]
    $hasExternalVendorCode: Boolean
    $coveredByBenefitType: [String!]
    $coveredByBenefitOutcome: [String!]
    $coveredByOpenApplicationStatus: [String!]
    $search: String
    $hospitalAccountId: String
    $invoiceMonth: [String!]
    $invoiceYear: [String!]
  ) {
    accounts(
      statuses: $statuses
      insuranceTypes: $insuranceTypes
      dateOfServiceAfter: $dateOfServiceAfter
      dateOfServiceBefore: $dateOfServiceBefore
      facility: $facility
      hasExternalVendorCode: $hasExternalVendorCode
      coveredByBenefitType: $coveredByBenefitType
      coveredByBenefitOutcome: $coveredByBenefitOutcome
      coveredByOpenApplicationStatus: $coveredByOpenApplicationStatus
      search: $search
      hospitalAccountId: $hospitalAccountId
      invoiceMonth: $invoiceMonth
      invoiceYear: $invoiceYear
    ) {
      ...AccountFields
      person {
        ...PersonForAccountFields
      }
    }
  }
  ${accountFragment}
  ${personForAccountFragment}
`

export const SIGNED_DOCUMENT_UPLOAD_URL_QUERY = gql`
  query SignedDocumentUploadUrl($fileKey: String!) {
    signedDocumentUploadUrl(fileKey: $fileKey) {
      url
    }
  }
`

export const SIGNED_DOCUMENT_DOWNLOAD_URL_QUERY = gql`
  query SignedDocumentDownloadUrl($fileKey: String!) {
    signedDocumentDownloadUrl(fileKey: $fileKey) {
      url
    }
  }
`

export const ACCOUNTS_FOR_CSV_QUERY = gql`
  query AccountsForCsv(
    $statuses: [String!]
    $insuranceTypes: [String!]
    $dateOfServiceAfter: ISO8601Date
    $dateOfServiceBefore: ISO8601Date
    $facility: [String!]
    $hasExternalVendorCode: Boolean
    $coveredByBenefitType: [String!]
    $coveredByBenefitOutcome: [String!]
    $coveredByOpenApplicationStatus: [String!]
    $search: String
    $password: String
    $invoiceMonth: [String!]
    $invoiceYear: [String!]
  ) {
    accounts(
      statuses: $statuses
      insuranceTypes: $insuranceTypes
      dateOfServiceAfter: $dateOfServiceAfter
      dateOfServiceBefore: $dateOfServiceBefore
      facility: $facility
      hasExternalVendorCode: $hasExternalVendorCode
      coveredByBenefitType: $coveredByBenefitType
      coveredByBenefitOutcome: $coveredByBenefitOutcome
      coveredByOpenApplicationStatus: $coveredByOpenApplicationStatus
      search: $search
      password: $password
      invoiceMonth: $invoiceMonth
      invoiceYear: $invoiceYear
    ) {
      ...AccountFields
      person {
        ...PersonForAccountFields
      }
      potentiallyCoveringBenefitOutcome {
        ...BenefitOutcomeFields
        application {
          id
        }
      }
      triage {
        id
        status
        closeReason
        closeComment
        createdAt
        updatedAt
        eligibilityDeterminations {
          ...EligibilityDeterminationFields
        }
      }
      openApplications {
        id
        benefit
        status
        createdAt
        updatedAt
        ... on MedicaidApplication {
          primaryPointOfContact {
            id
            firstName
            middleName
            lastName
            preferredName
            suffix
          }
          submitDate
          submitMethod
          location
          countyName
          confirmationNumber
          policyId
          notes
          detailsUpdatedAt
        }
        ... on MonitorApplication {
          primaryPointOfContact {
            id
            firstName
            middleName
            lastName
            preferredName
            suffix
          }
          submitDate
          submitMethod
          location
          countyName
          applicationType
          darSubmitDate
          confirmationNumber
          policyId
          notes
          detailsUpdatedAt
        }
        ... on CharityCareApplication {
          facility
          initialDateOfService
          dateOfApplication
          requestedDateOfApplication
          notes
          detailsUpdatedAt
        }
        ... on SlideApplication {
          facility
          initialDateOfService
          receivedDate
          notes
          detailsUpdatedAt
        }
        ... on RyanWhiteApplication {
          facility
          initialDateOfService
          receivedDate
          notes
          detailsUpdatedAt
        }
      }
    }
  }
  ${accountFragment}
  ${personForAccountFragment}
  ${benefitOutcomeFragment}
  ${eligibilityDeterminationFragment}
`

export const PEOPLE_QUERY = gql`
  query People(
    $name: String
    $dob: ISO8601Date
    $phoneNumber: String
    $hospitalPatientId: String
    $hospitalAccountId: String
    $personId: ID
    $ssn: String
    $doesNotHaveApplicationOfType: [String!]
    $openApplications: [String!]
    $mostRecentMedicaidEligibilityDetermination: [String!]
    $insuranceTypeOnMostRecentAccount: [String!]
    $hasMrn: [String!]
    $pastDateOfService: [String!]
    $futureDateOfService: [String!]
    $departmentOfMostRecentAccount: [String!]
    $dischargeDate: [String!]
    $missingBenefitOutcomeForDateOfService: [String!]
    $unreviewedWebformIds: [ID!]
    $hasUnreadTextMessages: [String!]
  ) {
    people(
      name: $name
      dob: $dob
      phoneNumber: $phoneNumber
      hospitalPatientId: $hospitalPatientId
      hospitalAccountId: $hospitalAccountId
      personId: $personId
      ssn: $ssn
      doesNotHaveApplicationOfType: $doesNotHaveApplicationOfType
      openApplications: $openApplications
      mostRecentMedicaidEligibilityDetermination: $mostRecentMedicaidEligibilityDetermination
      insuranceTypeOnMostRecentAccount: $insuranceTypeOnMostRecentAccount
      hasMrn: $hasMrn
      pastDateOfService: $pastDateOfService
      futureDateOfService: $futureDateOfService
      departmentOfMostRecentAccount: $departmentOfMostRecentAccount
      dischargeDate: $dischargeDate
      missingBenefitOutcomeForDateOfService: $missingBenefitOutcomeForDateOfService
      unreviewedWebformIds: $unreviewedWebformIds
      hasUnreadTextMessages: $hasUnreadTextMessages
    ) {
      ...PersonFields
      mostRecentAccount {
        ...AccountFields
      }
      openApplications {
        id
        benefit
      }
      mostRecentEligibilityDeterminations {
        ...EligibilityDeterminationFields
      }
    }
  }
  ${personFragment}
  ${accountFragment}
  ${eligibilityDeterminationFragment}
`

export const PEOPLE_WITH_UNREAD_TEXT_MESSAGES_QUERY = gql`
  query PeopleWithUnreadTextMessages {
    people(
      hasUnreadTextMessages: ["Yes"]
      openApplications: ["noOpenApplications"]
    ) {
      id
    }
  }
`

export const DUPLICATE_PEOPLE_QUERY = gql`
  query DuplicatePeople($password: String!) {
    duplicatePeople(password: $password) {
      id
      mainPersonId
      matchCriteria
      person {
        ...PersonFields
        createdAt
        relationships {
          relationshipType
          otherPerson {
            id
            firstName
            lastName
            middleName
            preferredName
            suffix
          }
        }
      }
    }
  }
  ${personFragment}
`

export const USERS_QUERY = gql`
  query Users(
    $includeBlockedUsersWithAssignedTasks: Boolean
    $includeBlockedUsersWithAssignedTriages: Boolean
    $includeBlockedUsersWithAssignedApplications: Boolean
    $includeBlockedUsersWithAssignedInbounds: Boolean
  ) {
    users(
      includeBlockedUsersWithAssignedTasks: $includeBlockedUsersWithAssignedTasks
      includeBlockedUsersWithAssignedTriages: $includeBlockedUsersWithAssignedTriages
      includeBlockedUsersWithAssignedApplications: $includeBlockedUsersWithAssignedApplications
      includeBlockedUsersWithAssignedInbounds: $includeBlockedUsersWithAssignedInbounds
    ) {
      ...UserFields
    }
  }
  ${userFragment}
`

export const APPLICATIONS_REPORT_QUERY = gql`
  query ApplicationsReport(
    $statuses: [String!]!
    $benefits: [String!]!
    $password: String!
  ) {
    applications(
      statuses: $statuses
      benefits: $benefits
      password: $password
    ) {
      ...ApplicationFields
      person {
        ...PersonForApplicationFields
        relationships {
          id
          relationshipType
          otherPerson {
            id
          }
        }
      }
      householdMembers {
        id
        wantsCoverage
        person {
          ...PersonForApplicationFields
        }
      }
      ... on MedicaidApplication {
        primaryPointOfContact {
          ...PersonForApplicationFields
        }
      }
      ... on MonitorApplication {
        primaryPointOfContact {
          ...PersonForApplicationFields
        }
      }
    }
  }
  ${applicationFragment}
  ${personForApplicationFragment}
`

export const BENEFIT_OUTCOMES_REPORT_QUERY = gql`
  query BenefitOutcomesReport($password: String!) {
    benefitOutcomes(password: $password) {
      ...BenefitOutcomeFields
      person {
        id
        firstName
        lastName
        middleName
        preferredName
        suffix
      }
      application {
        id
      }
      potentiallyCoveredAccounts {
        id
      }
    }
  }
  ${benefitOutcomeFragment}
`

export const WEBFORMS_QUERY = gql`
  query Webforms {
    webforms {
      ...WebformFields
    }
  }
  ${webformFragment}
`

export const RECEIVED_WEBFORMS_QUERY = gql`
  query ReceivedWebforms(
    $matchedStatus: String
    $webformId: [ID!]
    $appointmentDate: [String!]
    $isScreeningOutcomeEligible: Boolean
  ) {
    receivedWebforms(
      matchedStatus: $matchedStatus
      webformId: $webformId
      appointmentDate: $appointmentDate
      isScreeningOutcomeEligible: $isScreeningOutcomeEligible
    ) {
      ...ReceivedWebformFields
      webform {
        ...WebformFields
      }
    }
  }
  ${receivedWebformFragment}
  ${webformFragment}
`

export const CONFIG_QUERY = gql`
  query Config {
    config {
      ...ConfigFields
    }
  }
  ${configFragment}
`

export const INBOUNDS_QUERY = gql`
  query Inbounds(
    $serviceType: [String!]
    $dateOfService: [String!]
    $intakeWebformReceived: [String!]
    $startedById: [String!]
    $inboundWebformId: [String!]
    $openClosedStatus: String
    $inboundType: String
    $department: [String!]
    $financialOffice: [ID!]
    $inboundReason: [ID!]
    $openApplications: [String!]
  ) {
    inbounds(
      serviceType: $serviceType
      dateOfService: $dateOfService
      intakeWebformReceived: $intakeWebformReceived
      startedById: $startedById
      inboundWebformId: $inboundWebformId
      openClosedStatus: $openClosedStatus
      inboundType: $inboundType
      department: $department
      financialOffice: $financialOffice
      inboundReason: $inboundReason
      openApplications: $openApplications
    ) {
      ...InboundsQueryFields
    }
  }
  ${inboundsQueryFragment}
`

export const FILE_TEMPLATES_QUERY = gql`
  query FileTemplates {
    fileTemplates {
      ...FileTemplateFields
    }
  }
  ${fileTemplateFragment}
`

export const EDITABLE_FILES_QUERY = gql`
  query EditableFiles($applicationId: ID!) {
    editableFiles(applicationId: $applicationId) {
      ...EditableFileFields
      isUnreviewed(applicationId: $applicationId)
    }
  }
  ${editableFileFragment}
`

export const INBOUND_WEBFORMS_QUERY = gql`
  query InboundWebforms {
    inboundWebforms {
      ...InboundWebformFields
    }
  }
  ${inboundWebformFragment}
`

export const FILE_TEMPLATE_MAPPING_QUERY = gql`
  query FileTemplateMapping(
    $applicationId: ID!
    $receivedWebformFileTemplateId: ID
  ) {
    fileTemplateMapping(
      applicationId: $applicationId
      receivedWebformFileTemplateId: $receivedWebformFileTemplateId
    ) {
      mapping
    }
  }
`

export const PERSON_RECEIVED_WEBFORM_FILE_TEMPLATES_QUERY = gql`
  query PersonReceivedWebformFileTemplates($id: ID!) {
    person(id: $id) {
      id
      mostRecentReceivedWebform {
        id
        createdAt
        receivedWebformFileTemplates {
          id
          cleared
          fileTemplate {
            id
            name
          }
          receivedWebform {
            id
            response
          }
        }
      }
    }
  }
`

export const PEOPLE_BY_MRN_QUERY = gql`
  query PeopleByMrn($hospitalPatientId: String!) {
    peopleByMrn(hospitalPatientId: $hospitalPatientId) {
      id
      firstName
      middleName
      lastName
      suffix
      preferredName
      dob
      editableFiles {
        ...EditableFileFields
        hasIncompleteRemoteDocumentRequest
      }
      openApplications {
        id
      }
    }
  }
  ${editableFileFragment}
`
export const REMOTE_DOCUMENT_REQUESTS_QUERY = gql`
  query RemoteDocumentRequests($personId: ID!) {
    remoteDocumentRequests(personId: $personId) {
      id
      phoneNumber
      requestReceivedAt
      requestCancelled
      sessionId
      requestInitiatedBy {
        id
        name
      }
      personId
      applicationId
      createdAt
      updatedAt
      documentType
      requestorType
      editableFileId
      documentName
    }
  }
`

export const TEXT_MESSAGES_QUERY = gql`
  query TextMessages($personId: ID!) {
    textMessages(personId: $personId) {
      id
      phoneNumber
      message
      sessionId
      messageInitiatedBy {
        id
        name
      }
      messageType
      personId
      applicationId
      createdAt
      updatedAt
    }
  }
`

export const SENTWEBFORMS_PENDING_QUERY = gql`
  query SentWebForms($personId: ID!) {
    sentWebForms(personId: $personId) {
      id
      personId
      phoneNumber
      webFormName
      requestInitiatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`
export const EVENT_LOG_QUERY = gql`
  query EventLogs($personId: ID!, $applicationId: ID) {
    eventLogs(personId: $personId, applicationId: $applicationId) {
      id
      eventName
      eventMessage
      sentFlag
      sentAt
      eventInitiatedBy {
        id
        name
      }
      personId
      applicationId
      createdAt
      updatedAt
    }
  }
`

export const NUMBER_OF_PEOPLE_WITH_UNREVIEWED_RECEIVED_WEBFORMS_QUERY = gql`
  query NumberOfPeopleWithUnreviewedReceivedWebforms($webformIds: [ID!]) {
    numberOfPeopleWithUnreviewedReceivedWebforms(webformIds: $webformIds)
  }
`

export const NJMMIS_CAPTURE_RESULT_QUERY = gql`
  query NjmmisCaptureResult($id: ID!) {
    njmmisCaptureResult(id: $id) {
      id
      status
    }
  }
`

export const COUNTY_FAXES_QUERY = gql`
  query CountyFaxes($stateId: ID) {
    countyFaxes(stateId: $stateId) {
      ...CountyFaxFields
      state {
        ...StateFields
      }
    }
  }
  ${countyFaxFragment}
  ${stateFieldFragment}
`
export const AUDIT_ZIPFILES_QUERY = gql`
  query AuditZipfiles($auditId: ID) {
    auditZipfiles(auditId: $auditId) {
      id
      zipCategory
      documentFiles {
        ...DocumentFileFields
      }
    }
  }
  ${documentFileFragment}
`

export const AUDIT_QUERY = gql`
  query Audits {
    audits {
      id
      name
      fileCounter
      createdAt
    }
  }
`

export const SLIDE_APPLICATION_RULES_QUERY = gql`
  query SlideApplicationRules {
    slideApplicationRules {
      id
      ruleType
      ruleNumber
      ruleSequence
      incomePercentage
      plan
      copayInsuredUninsured
      reducedFee
    }
  }
`
